<script>
import { computed } from 'vue'

export default {
    props: {
        route: {
            type: String,
            required: true,
        },

        elementId: {
            type: Number,
            required: true,
        },

        data: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
    },

    inheritAttrs: false,

    data() {
        return {
            content: {},
            url: "",
            loading: false,
            newContent: false,
        };
    },

    provide() {
        return {
            content: computed(() => this.content),
        }
    },

    mounted() {
        this.content = this.data !== null ? this.data : {};
        this.url = Routing.generate(this.route, { id: this.elementId });


        Tigris.loadForm(document.getElementById("content-editor"));

        document.body.classList.add("has-wysiwyg");
        this.newcontent = false;

        this.$watch('content', () => {
            this.newContent = true
        }, { deep: true })
    },

    methods: {
        save() {
            this.loading = true;
            this.$http
                .post(this.url, { data: this.content })
                .then((response) => {
                    this.loading = false;
                    this.newContent = false;
                    Tigris.alert()
                        .create(response.data.message, response.data.status)
                        .life(4000);
                });
        },
    },

};
</script>

<template>
    <slot :content="content"></slot>

    <button class="btn-save" :class="{ disabled: loading, pulse: newContent }" v-on:click="save()">
        <span>Enregistrer</span>
        <i v-if="loading" :class="'fa' + iconStyle + ' fa-circle-notch fa-spin'"></i>
        <i v-else :class="'fa' + iconStyle + ' fa-floppy-disk'"></i>
    </button>
</template>

<style scoped>
.btn-save {
    position: fixed;
    color: white;
    background-color: #333;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 25px 25px 0;
    left: 0;
    bottom: 20vh;
    padding: 12px 22px 12px 16px;
    height: 50px;
    border: none;
    transition: opacity 0.3s, transform 0.4s;
    transform: translateX(-90px);
}

.btn-save>i {
    font-size: 1.8em;
}

.btn-save>span {
    opacity: 0;
    transition: opacity 0.4s;
    display: block;
    margin: 4px 12px;
    font-size: 1em;
}

.btn-save:hover {
    opacity: 1;
    transform: translateX(0px);
}

.btn-save:hover>span {
    opacity: 1;
}

.btn-save.pulse {
    animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
</style>