<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    inject: ['content'],
    data: () => {
        return {};
    },

    methods: {
        selectFile() {
            const onClick = (dialog) => {
                let selected = dialog.DOMElement.querySelector(
                    ".select-file:checked"
                );

                if (selected === null) {
                    return;
                }

                let type = selected
                    .closest(".file-item")
                    .getAttribute("data-type");
                let name = selected
                    .closest(".file-item")
                    .getAttribute("data-name");
                let path = selected
                    .closest(".file-item")
                    .getAttribute("data-path");
                let size = selected
                    .closest(".file-item")
                    .getAttribute("data-size");
                let id = selected.closest(".file-item").getAttribute("data-id");
                selected.checked = false;

                if (type === "image") {
                    this.content[this.name] = path;
                    this.$emit("input", path);
                }

                dialog.close()
            }

            Tigris.openFileSelectorDialog().then(dialog => {
                dialog.onValid = () => {
                    onClick(dialog)
                }
            })
        },

        remove() {
            this.content[this.name] = '';
            this.$emit("input", '');
        }
    },
};
</script>

<template>
    <div class="content-image-widget">
        <button class="btn btn-edit" v-html="$filters.parseIcons('.icon-pencil')" v-on:click="selectFile"></button>
        <button class="btn btn-remove" v-if="content[name] !== ''" v-html="$filters.parseIcons('.icon-trash')"
            v-on:click="remove"></button>
        <img :src="content[name]" alt="" />
    </div>
</template>

<style scoped>
.content-image-widget {
    position: relative;
}

.content-image-widget>.btn {
    position: absolute;
    top: 4px;
    left: 4px;
    opacity: 0.6;
    padding: 1px 6px;
    background-color: white;
    border-radius: 50%;
    margin-right: 6px;
}

.content-image-widget>.btn-remove {
    background-color: #ffc6c6;
    left: 36px;
}

.content-image-widget>.btn:hover {
    opacity: 1;
}
</style>